import React, { Component } from 'react'
import { Link } from 'gatsby'
// import { StaticImage } from 'gatsby-plugin-image'
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../layouts/App"
import SEO from "../components/seo"

import Fade from "react-reveal/Fade"

import ReactPlayer from 'react-player'
import ReactModal from 'react-modal'
import Div100vh from '../utils/Div100vh'
import Close from "../svg/Close"

import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import TrackVisibility from "react-on-screen"

import Spotlight from '../components/Spotlight'

const settings = {
    arrows: true,
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true
}
export default class workDetail extends Component {
    constructor() {
        super()
        this.state = {
            quickLookMode: false,
            modalIsClosing: false,
            playing: false
        }
        // event for toggling modal with quickview mode
        // this.toggleMode = this.toggleMode.bind(this)
    }

    listMedias() {
        let slides = []
        {this.props.pageContext.node.data !== null && (this.props.pageContext.node.data.map(workData => {
            if (workData.dataType === "core/image") {
                let wokrImage;
                if (workData.image.large) {
                    wokrImage = workData.image.large;
                }
                else {
                    wokrImage = workData.image.large;
                }   
                slides.push(
                    <div className="img__Block" style={{backgroundImage:`url("${wokrImage}")`}}></div>
                )
            }
            if (workData.dataType === "core/gallery") {
                {workData.gallery.map(galleryImage => {
                    let galleryUrl;                    
                    if (galleryImage.large) {
                        galleryUrl = galleryImage.large;
                    }
                    else {
                        galleryUrl = galleryImage.large;
                    }   
                    slides.push(
                      <div className="img__Block" style={{backgroundImage:`url("${galleryUrl}")`}}></div>
                    )
                    // slides.push(
                    //     <div className="img__Block" style={{backgroundImage:`url("${galleryImage.large.sourceUrl}")`}}></div>
                    // )
                })}
            }
            if (workData.dataType === "core/video") {
                slides.push(
                    <div className="block iframe">
                        <ReactPlayer url={workData.videoURL} muted={true} controls={true} />
                    </div>
                )
            }
            if (workData.dataType === "core-embed/youtube" || workData.embedType === "youtube") {
                slides.push(
                    <div className="block iframe">
                        <ReactPlayer url={`https://www.youtube.com/embed/${workData.embedURL}`} controls={true} />
                    </div>
                )
            }
            if (workData.dataType === "core-embed/vimeo" || workData.embedType === "vimeo") {
                slides.push(
                    <div className="block iframe">
                        <ReactPlayer url={`https://player.vimeo.com/video/${workData.embedURL}?background=1`} controls={true}  />
                    </div>
                )
            }
            
        }))}

        return slides.map((slide, index) => (
        <div key={index} className="work__single__quickview__slide">
            <div className="inner">{slide}</div>
        </div>
        ))
    }

    langSwitcher(){
        let langKey = []
        if (this.props.pageContext.node.languageCode === "en") {
            langKey.splice(0,0,
                <Link to={`en/work/${this.props.pageContext.node.slug}`} className="active">ENG</Link>
            )
        }
        if (this.props.pageContext.node.languageCode === "ja") {
            langKey.splice(1,0,
                <>/<Link to={`/work/${this.props.pageContext.node.slug}`} className="active">JPN</Link></>
            )
        }
        if (this.props.pageContext.node.languageCode === "ko") {
            langKey.splice(2, 0,
                <>/<Link to={`/ko/work/${this.props.pageContext.node.slug}`} className="active">KOR</Link></>
            )
        }
        {this.props.pageContext.node.locale !== null && (this.props.pageContext.node.locale.map(languages => {
            if (languages.locale === "en_US") {
                langKey.splice(0, 0,
                    <Link to={`/en/work/${this.props.pageContext.node.slug}`}>ENG</Link>
                )
            }
            if (languages.locale === "ja_JP") {
                langKey.splice(1,0,
                    <>/<Link to={`/work/${this.props.pageContext.node.slug}`}>JPN</Link></>
                )
            }
            if (languages.locale === "ko_KR") {
                langKey.splice(2, 0,
                    <>/<Link to={`/ko/work/${this.props.pageContext.node.slug}`}>KOR</Link></>
                )
            }
        }))}
        return langKey
    }

    toggleMode(e = null, modalIndex = 0) {
        if (e) e.preventDefault()
        this.setState({
            quickLookMode: !this.state.quickLookMode ? true : false,
            modalIsClosing: false,
            modalIndex: modalIndex,
        })
    }

    closeModal(e = null) {
        if (e) e.preventDefault()
        if (!this.state.modalIsClosing) {
            this.setState({
                modalIsClosing: true,
            })
            setTimeout(() => {
                this.toggleMode(e)
            }, 1000)
        }
    }
    modalClassName(base = `modal`) {
        let classes = [base]
        if (this.state.modalIsClosing) {
        classes.push(`is-closing`)
        }
        return classes.join(` `)
    }
    playerIsVisible({isVisible}){
        // console.log(isVisible)
    }
  render() {
      const ComponentToTrack = props => {
        return (
            <div className="block iframe">
                <ReactPlayer
                    url={props.URL}
                    // playing={props.isVisible}
                    controls={true}
                    muted={true}
                    loop={true}
                    width="100%"
                    height="100%"
                />
            </div>
        )
      }
    return (
      <Layout>
            <SEO title={this.props.pageContext.node.workInfo.client + " : " +this.props.pageContext.node.title} image={this.props.pageContext.node.acf.workImage.sourceUrl} description={this.props.pageContext.node.acf.workExcerpt || this.props.pageContext.node.acf.metaPageDescription} />
            <Fade bottom speed={450} delay={25} distance="5%">
            <article>
                <Spotlight image={this.props.pageContext.node.acf.workImage.sourceUrl} />
                <section className="container-fluid-custom-only work_detail_page min--width--coloumn--work">
                    <h1 className="work--page--title" dangerouslySetInnerHTML={{__html:this.props.pageContext.node.workInfo.client + " - " +this.props.pageContext.node.title}} />
                    {/* <h1 dangerouslySetInnerHTML={{__html:this.props.pageContext.node.title}} /> */}
                    <div className="work_detail_page__Legend">
                        <ul className="project">
                            {/* {this.props.pageContext.node.workInfo.client !== null && (<li>Client: {this.props.pageContext.node.workInfo.client}</li>)} */}
                            {this.props.pageContext.node.acf.launchDate && 
                              <li>Date: {this.props.pageContext.node.acf.launchDate}</li>
                            }
                        </ul>
                        {/* <div className="language-switcher">
                            {this.langSwitcher()}
                        </div> */}
                    </div>
                    {this.props.pageContext.node.data !== null && (this.props.pageContext.node.data.map((workContent, index) => {
                        // console.log(workContent)
                        return(
                            <React.Fragment key={index}>
                                {workContent.dataType === "core/paragraph" && (
                                    <div className="work_detail_page__Para" dangerouslySetInnerHTML={{__html:workContent.content}} />
                                )}
                                {workContent.dataType === "core/list" && (
                                    <p dangerouslySetInnerHTML={{__html:workContent.content}}></p>
                                )}
                                {workContent.dataType === "core/work-result" && (
                                    <div className="work_detail_page__Para">
                                        <h3>Results</h3>
                                        <p dangerouslySetInnerHTML={{__html:workContent.content}} />
                                    </div>
                                )}
                                {workContent.dataType === "core/image" && workContent.image.medium !== null && (
                                    <div className="work_detail_page__Creatives">
                                        <div className="block">
                                            <a href="true" onClick={e => this.toggleMode(e)}>
                                                <img src={workContent.image.medium} alt="" />
                                                {/* <StaticImage src={workContent.image.large} alt="" /> */}
                                                {/* <GatsbyImage image={getImage(workContent.image.large)} alt="" /> */}
                                            </a>    
                                        </div>
                                    </div>
                                )}
                                {workContent.dataType === "core/gallery" && (
                                    <div className="work_detail_page__Creatives">
                                        <div className="block gallery">
                                            {workContent.gallery.map(galleryItem => {
                                                return(
                                                    <>
                                                    {galleryItem.medium !== null && (<div className="gallery__Item" key={galleryItem.medium.id}>
                                                        <a href="true" onClick={e => this.toggleMode(e)}>
                                                            <img src={galleryItem.medium} alt="" />
                                                        </a>
                                                    </div>)}
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}
                                {workContent.dataType === "core/columns" && (
                                    <div className="work_detail_page__Creatives">
                                        <div className="block gallery">
                                            {workContent.videoURLS.map(galleryItem => {
                                                return(
                                                    <div className="gallery__Item" key={galleryItem}>
                                                        <ReactPlayer url={galleryItem} width='100%' height='100%' controls={false} playing loop={true}  />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}
                                {workContent.dataType === "core/media-text" && (
                                    <div className="work_detail_page__Creatives">
                                        <div className="block mediaText">
                                            {workContent.mediaPosition === "right" && (
                                                <>
                                                    <div className="items" dangerouslySetInnerHTML={{__html:workContent.content}} />
                                                    <div className="items"><a href="javascript:;"><img src={workContent.image.large} alt="" /></a></div>
                                                </>
                                            )}
                                            {workContent.mediaPosition === "left" && (
                                                <>
                                                    <div className="items"><a href="javascript:;"><img src={workContent.image.large} alt="" /></a></div>
                                                    <div className="items" dangerouslySetInnerHTML={{__html:workContent.content}} />
                                                </>
                                            )}
                                            {workContent.mediaPosition === "null" && (
                                                <>
                                                    <div className="items"><a href="javascript:;"><img src={workContent.image.large} alt="" /></a></div>
                                                    <div className="items" dangerouslySetInnerHTML={{__html:workContent.content}} />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {workContent.dataType === "core/video" && (
                                    <div className="work_detail_page__Creatives">
                                        <TrackVisibility partialVisibility offset={-150}>
                                            <ComponentToTrack URL={workContent.videoURL} />
                                        </TrackVisibility>
                                    </div>
                                )}
                                {(workContent.dataType === "core-embed/youtube" || workContent.embedType === "youtube") && (
                                    <div className="work_detail_page__Creatives">
                                        <TrackVisibility partialVisibility offset={-150}>
                                            <ComponentToTrack URL={`https://www.youtube.com/embed/${workContent.embedURL}`} />
                                        </TrackVisibility>
                                    </div>
                                )}
                                {(workContent.dataType === "core-embed/vimeo" || workContent.embedType === "vimeo") && (
                                    <div className="work_detail_page__Creatives">
                                        <TrackVisibility partialVisibility offset={-150}>
                                            <ComponentToTrack URL={`https://player.vimeo.com/video/${workContent.embedURL}?background=1`} />
                                        </TrackVisibility>
                                    </div>
                                )}
                            </React.Fragment>
                        )
                    }))}
                </section>
            </article>
            </Fade>
            {/* All work model */}
            <ReactModal 
                className={this.modalClassName(`modal__main`)}
                overlayClassName={this.modalClassName(`modal__overlay`)}
                onRequestClose={e => this.closeModal(e)}
                isOpen={this.state.quickLookMode}
                >
                <Div100vh>
                    <div className="modal__inner">
                        <a
                        href="true"
                        className="modal__close"
                        onClick={e => this.closeModal(e)}
                        >
                        <Close />
                        </a>
                        <header className="modal__header is-text-center">
                            <h5 className="no-margin" dangerouslySetInnerHTML={{__html:this.props.pageContext.node.title}} />
                        </header>
                        <div className="modal__content">
                        <div ref="slider" className="work__single__quickview">
                            <Slider {...settings}>
                                {this.listMedias()}
                            </Slider>
                        </div>
                        </div>
                    </div>
                </Div100vh>
            </ReactModal>
        </Layout>
    )
  }
}